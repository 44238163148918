<template>
    <div v-if="isLoading" class="loader-container">
        <div class="text-center">
            <div class="text-secondary mb-3">Loading data (this might take a moment)</div>
            <div class="progress progress-sm">
                <div class="progress-bar progress-bar-indeterminate"></div>
            </div>
        </div>
    </div>

    <br />

    <div class="page-header d-print-none">
        <div class="container-xl">
            <div class="row g-2 align-items-center">
                <div class="col">
                    <div class="page-pretitle">
                        Microsoft Patch
                    </div>
                    <h2 class="page-title">
                        Annual Patch Trends
                    </h2>
                </div>
                <div class="col-auto ms-auto d-print-none">
                    <div class="input-group">
                        <span class="input-group-text bg-white">
                            <IconCalendar class="icon" stroke-width="2" color="currentColor" />
                        </span>
                        <select v-model="selectedYear" @change="fetchDataForAllMonths">
                            <option v-for="year in availableYears" :key="year" :value="year">&nbsp;{{ year }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div><br />

    <div class="container">
        <div class="col-12">
            <div class="card modern-card shadow-sm">
                <div class="card-header">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-urgent" width="24"
                        height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                        stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M8 16v-4a4 4 0 0 1 8 0v4"></path>
                        <path d="M3 12h1m8 -9v1m8 8h1m-15.4 -6.4l.7 .7m12.1 -.7l-.7 .7"></path>
                        <path d="M6 16m0 1a1 1 0 0 1 1 -1h10a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-10a1 1 0 0 1 -1 -1z">
                        </path>
                    </svg>&nbsp;
                    <h3 class="card-title">Vulnerabilities by Severity per Month - {{ selectedYear }}</h3>
                </div>
                <div class="card-body">
                    <div id="severityChart"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="container mt-3">
        <div class="col-12">
            <div class="card modern-card shadow-sm">
                <div class="card-header">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-virus-search" width="24"
                        height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                        stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M17 12a5 5 0 1 0 -5 5"></path>
                        <path d="M12 7v-4"></path>
                        <path d="M11 3h2"></path>
                        <path d="M15.536 8.464l2.828 -2.828"></path>
                        <path d="M17.657 4.929l1.414 1.414"></path>
                        <path d="M17 12h4"></path>
                        <path d="M21 11v2"></path>
                        <path d="M12 17v4"></path>
                        <path d="M13 21h-2"></path>
                        <path d="M8.465 15.536l-2.829 2.828"></path>
                        <path d="M6.343 19.071l-1.413 -1.414"></path>
                        <path d="M7 12h-4"></path>
                        <path d="M3 13v-2"></path>
                        <path d="M8.464 8.464l-2.828 -2.828"></path>
                        <path d="M4.929 6.343l1.414 -1.413"></path>
                        <path d="M17.5 17.5m-2.5 0a2.5 2.5 0 1 0 5 0a2.5 2.5 0 1 0 -5 0"></path>
                        <path d="M19.5 19.5l2.5 2.5"></path>
                    </svg>&nbsp;
                    <h3 class="card-title">Threat Category Distribution per Month - {{ selectedYear }}</h3>
                </div>
                <div class="card-body">
                    <div id="categoryChart"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="container mt-3">
        <div class="col-12">
            <div class="card modern-card shadow-sm">
                <div class="card-header">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-windows"
                        width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                        stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path
                            d="M17.8 20l-12 -1.5c-1 -.1 -1.8 -.9 -1.8 -1.9v-9.2c0 -1 .8 -1.8 1.8 -1.9l12 -1.5c1.2 -.1 2.2 .8 2.2 1.9v12.1c0 1.2 -1.1 2.1 -2.2 1.9z">
                        </path>
                        <path d="M12 5l0 14"></path>
                        <path d="M4 12l16 0"></path>
                    </svg>&nbsp;
                    <h3 class="card-title">Affected Products Total - {{ selectedYear }}</h3>
                </div>
                <div class="card-body">
                    <div id="nameChart"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="container mt-3">
        <div class="col-12">
            <div class="card modern-card shadow-sm">
                <div class="card-header">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-shield-lock" width="24"
                        height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                        stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M12 3l8 4v5a8 8 0 1 1 -16 0v-5z" />
                        <path d="M12 10m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                        <path d="M12 13l0 2.5" />
                    </svg>&nbsp;
                    <h3 class="card-title">CWE Distribution - {{ selectedYear }}</h3>
                </div>
                <div class="card-body">
                    <div id="cweChart"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="container mt-3">
        <div class="col-12">
            <div class="card modern-card shadow-sm">
                <div class="card-header">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-star" width="24"
                        height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                        stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path
                            d="M12 17.75l-6.172 3.245l1.179-6.874l-5-4.873l6.907-1.003l3.086-6.26l3.086 6.26l6.907 1.003l-5 4.873l1.179 6.874z" />
                    </svg>&nbsp;
                    <h3 class="card-title">Average CVSS Score per Month - {{ selectedYear }}</h3>
                </div>
                <div class="card-body">
                    <div id="averageCVSSChart"></div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
/* global Highcharts */
import config from '@/config';
import { IconCalendar } from '@tabler/icons-vue';

export default {
    name: 'App',
    components: {
        IconCalendar
    },
    data() {
        return {
            isLoading: true,
            selectedYear: new Date().getFullYear(),
            availableYears: this.initializeAvailableYears(),
            vulnerabilities: [],
            totalVulnerabilities: 0,
            exploitedCount: 0,
            categoryCounts: {},
            nameCounts: {},
            countedVulnerabilities: new Set(),
            productTree: {},
            summaries: [],
            BASE_URL: config.BASE_URL,
            selectedMonth: 'Select Month',
            severityCounts: {
                'Critical': 0,
                'High': 0,
                'Medium': 0,
                'Low': 0
            },
            cweCounts: {},
            currentPage: 1,
            itemsPerPage: 10
        };
    },
    computed: {
        exploitedVulnerabilities() {
            const exploitedVulns = this.vulnerabilities.filter(vuln => this.extractSeverityAndExploitation(vuln) === 'Exploited');
            return this.sortVulnerabilities(exploitedVulns);
        },
        notExploitedCount() {
            return this.vulnerabilities.length - this.exploitedCount;
        },
        notExploitedVulnerabilities() {
            const nonExploitedVulns = this.vulnerabilities.filter(vuln => this.extractSeverityAndExploitation(vuln) !== 'Exploited');
            return this.sortVulnerabilities(nonExploitedVulns);
        },
        overallStats() {
            return [
                { title: 'Total vulnerabilities', value: this.totalVulnerabilities },
                { title: 'Critical vulnerabilities', value: this.severityCounts['Critical'] },
                { title: 'High vulnerabilities', value: this.severityCounts['High'] },
                { title: 'Medium vulnerabilities', value: this.severityCounts['Medium'] },
                { title: 'Low vulnerabilities', value: this.severityCounts['Low'] },
                { title: 'Exploited vulnerabilities', value: this.exploitedCount },
                { title: 'Not Exploited vulnerabilities', value: this.notExploitedCount }
            ];
        },
        sortedCategoryCounts() {
            return Object.entries(this.categoryCounts)
                .sort((a, b) => b[1] - a[1])
                .map(([category, count]) => ({ category, count }));
        },
        totalNotExploitedPages() {
            return Math.ceil(this.notExploitedVulnerabilities.length / this.itemsPerPage);
        },
    },
    async mounted() {
        this.initializeAvailableYears();
        const summaries = await this.retrieveAllSummaries();
        if (summaries && summaries.length > 0) {
            this.selectedMonth = this.summaries[0].ID;
            await this.retrieveDataForMonth(this.selectedMonth);
            this.isLoading = false;
        }
        await this.fetchDataForAllMonths();
    },
    methods: {
        initializeAvailableYears() {
            const currentYear = new Date().getFullYear();
            let years = [];
            for (let year = 2023; year <= currentYear; year++) {
                years.push(year.toString());
            }
            return years;
        },
        async fetchDataForAllMonths() {
            const months = this.summaries.filter(summary => summary.ID.startsWith(`${this.selectedYear}-`))
                .map(summary => summary.ID)
                .reverse();
            this.nameCounts = {};
            this.countedVulnerabilities.clear();
            this.cweCounts = {}; // Reset CWE counts

            const severityResults = {
                'Critical': [],
                'High': [],
                'Medium': [],
                'Low': [],
                'N/A': [],
            };

            const categoryResults = {};
            const categories = ["Elevation of Privilege", "Security Feature Bypass", "Remote Code Execution", "Information Disclosure", "Denial of Service", "Spoofing", "Edge - Chromium"];
            for (let category of categories) {
                categoryResults[category] = [];
            }

            const accumulatedNameCounts = {};
            const averageCVSSScores = [];

            for (let month of months) {
                await this.retrieveDataForMonth(month);
                this.computeNameStatistics();

                severityResults['Critical'].push(this.severityCounts['Critical']);
                severityResults['High'].push(this.severityCounts['High']);
                severityResults['Medium'].push(this.severityCounts['Medium']);
                severityResults['Low'].push(this.severityCounts['Low']);
                severityResults['N/A'].push(this.vulnerabilities.filter(vuln => vuln.CVSSScoreSets.length === 0).length);

                for (let category of categories) {
                    categoryResults[category].push(this.categoryCounts[category]);
                }
                for (const [name, count] of Object.entries(this.nameCounts)) {
                    if (!accumulatedNameCounts[name]) {
                        accumulatedNameCounts[name] = 0;
                    }
                    accumulatedNameCounts[name] += count;
                }

                // Accumulate CWE counts
                for (const vuln of this.vulnerabilities) {
                    if (vuln.CWE) {
                        for (let cwe of vuln.CWE) {
                            const key = `${cwe.ID} ${cwe.Value}`;
                            if (this.cweCounts[key]) {
                                this.cweCounts[key]++;
                            } else {
                                this.cweCounts[key] = 1;
                            }
                        }
                    }
                }

                // Calculate the average CVSS score for the month
                const averageCVSSScore = this.calculateAverageCVSSScore();
                averageCVSSScores.push(averageCVSSScore);
            }

            this.computeNameStatistics();
            this.renderSeverityChart(months, severityResults);
            this.renderCategoryChart(months, categoryResults);
            this.renderNameChart();
            this.renderCWEChart();
            this.renderAverageCVSSChart(months, averageCVSSScores);
        },
        computeNameStatistics() {
            if (!Array.isArray(this.productTree.Branch)) {
                console.error('this.productTree.Branch is not iterable');
                return;
            }

            const product_id_to_name = {};

            for (const branch of this.productTree.Branch) {
                for (const item of branch.Items) {
                    const name = item.Name;
                    for (const sub_item of item.Items) {
                        const product_id = sub_item.ProductID;
                        product_id_to_name[product_id] = name;
                    }
                }
            }

            for (const vuln of this.vulnerabilities) {
                const uniqueNames = new Set();
                const vulnID = vuln.CVE;

                if (this.countedVulnerabilities.has(vulnID)) {
                    continue;
                }

                for (const product_status of vuln['ProductStatuses']) {
                    const product_ids = product_status['ProductID'];
                    for (const product_id of product_ids) {
                        const name = product_id_to_name[product_id];
                        if (name) {
                            uniqueNames.add(name);
                        }
                    }
                }

                for (const name of uniqueNames) {
                    if (!this.nameCounts[name]) {
                        this.nameCounts[name] = 0;
                    }
                    this.nameCounts[name]++;
                }
                this.countedVulnerabilities.add(vulnID);
            }
        },
        renderSeverityChart(months, results) {
            Highcharts.chart('severityChart', {
                chart: {
                    type: 'column'
                },
                title: {
                    text: ''
                },
                xAxis: {
                    categories: months
                },
                yAxis: {
                    title: {
                        text: 'Number of Vulnerabilities'
                    }
                },
                plotOptions: {
                    column: {
                        dataLabels: {
                            enabled: true
                        }
                    }
                },
                series: [{
                    name: 'N/A',
                    data: results['N/A'],
                    color: '#4298E1'
                }, {
                    name: 'Low',
                    data: results['Low'],
                    color: '#30B344'
                }, {
                    name: 'Medium',
                    data: results['Medium'],
                    color: '#F4A10B'
                }, {
                    name: 'High',
                    data: results['High'],
                    color: '#F76708'
                }, {
                    name: 'Critical',
                    data: results['Critical'],
                    color: '#D63939'
                }],
                credits: {
                    enabled: false
                }
            });
        },
        renderCategoryChart(months, results) {
            const series = Object.keys(results).map(category => ({
                name: category,
                data: results[category]
            }));

            Highcharts.chart('categoryChart', {
                chart: {
                    type: 'column'
                },
                title: {
                    text: ''
                },
                xAxis: {
                    categories: months
                },
                yAxis: {
                    title: {
                        text: 'Number of Threats'
                    }
                },
                plotOptions: {
                    column: {
                        dataLabels: {
                            enabled: true
                        }
                    }
                },
                series: series,
                credits: {
                    enabled: false
                }
            });
        },
        renderNameChart() {
            const categories = Object.keys(this.nameCounts);
            const data = Object.values(this.nameCounts);

            Highcharts.chart('nameChart', {
                chart: {
                    type: 'column'
                },
                title: {
                    text: ''
                },
                xAxis: {
                    categories: categories
                },
                yAxis: {
                    title: {
                        text: 'Number of Vulnerabilities'
                    }
                },
                plotOptions: {
                    column: {
                        dataLabels: {
                            enabled: true
                        }
                    }
                },
                series: [{
                    name: 'Vulnerabilities',
                    data: data
                }],
                credits: {
                    enabled: false
                }
            });
        },
        renderCWEChart() {
            // Extract and sort CWE data by count in descending order
            const sortedCWE = Object.entries(this.cweCounts)
                .sort((a, b) => b[1] - a[1])
                .map(([key, count]) => ({ key, count }));

            const categories = sortedCWE.map(item => item.key);
            const data = sortedCWE.map(item => item.count);

            Highcharts.chart('cweChart', {
                chart: {
                    type: 'column'
                },
                title: {
                    text: ''
                },
                xAxis: {
                    categories: categories
                },
                yAxis: {
                    title: {
                        text: 'Number of Vulnerabilities'
                    }
                },
                plotOptions: {
                    column: {
                        dataLabels: {
                            enabled: true,
                            format: '{point.y}' // Display count on bars
                        }
                    }
                },
                series: [{
                    name: 'CWE',
                    data: data,
                    color: '#8E44AD'
                }],
                credits: {
                    enabled: false
                }
            });
        },
        renderAverageCVSSChart(months, averageCVSSScores) {
            Highcharts.chart('averageCVSSChart', {
                chart: {
                    type: 'line'
                },
                title: {
                    text: ''
                },
                xAxis: {
                    categories: months
                },
                yAxis: {
                    title: {
                        text: 'Average CVSS Score'
                    }
                },
                series: [{
                    name: 'Average CVSS Score',
                    data: averageCVSSScores,
                    color: '#FF5733'
                }],
                credits: {
                    enabled: false
                }
            });
        },
        calculateAverageCVSSScore() {
            const scores = this.vulnerabilities.map(vuln => {
                const score = parseFloat(this.getCVSSScore(vuln));
                return isNaN(score) ? 0 : score;
            });
            const totalScore = scores.reduce((sum, score) => sum + score, 0);
            return scores.length ? totalScore / scores.length : 0;
        },
        async retrieveAllSummaries() {
            try {
                const response = await fetch(`${this.BASE_URL}updates`, {
                    headers: { Accept: "application/json" }
                });
                if (response.ok) {
                    const responseData = await response.json();

                    this.summaries = responseData.value.sort((a, b) => {
                        const [yearA, monthA] = a.ID.split('-');
                        const [yearB, monthB] = b.ID.split('-');
                        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                        if (yearA !== yearB) {
                            return parseInt(yearB) - parseInt(yearA);
                        } else {
                            return months.indexOf(monthB) - months.indexOf(monthA);
                        }
                    });

                    this.selectedMonth = this.summaries[0].ID;
                    await this.retrieveDataForMonth(this.selectedMonth);

                } else {
                    throw new Error("Failed to fetch summaries");
                }
            } catch (error) {
                console.error('Error fetching the summaries:', error);
            }
        },
        async retrieveDataForMonth(monthId) {
            this.isLoading = true;
            this.vulnerabilities = [];
            this.selectedMonth = monthId;
            try {
                const response = await fetch(`${this.BASE_URL}cvrf/${monthId}`, {
                    headers: { Accept: "application/json" }
                });
                if (response.ok) {
                    const data = await response.json();
                    this.vulnerabilities = this.vulnerabilities.concat(data.Vulnerability);
                    this.productTree = Object.assign({}, this.productTree, data.ProductTree);

                    this.computeStatistics();
                    this.isLoading = false;
                } else {
                    throw new Error("Failed to fetch data for the month");
                }
            } catch (error) {
                console.error(`Error fetching the data for month ${monthId}:`, error);
            }
        },
        retrieveDataForSelectedMonth() {
            this.retrieveDataForMonth(this.selectedMonth);
        },
        extractSeverityAndExploitation(vuln) {
            let exploitedStatus = "Not Exploited";
            for (let threat of vuln.Threats || []) {
                let description = threat.Description.Value || "";
                if (description.includes("Exploited:Yes")) {
                    exploitedStatus = "Exploited";
                }
            }
            return exploitedStatus;
        },
        getCVSSScore(vuln) {
            let cvssSets = vuln.CVSSScoreSets;
            return cvssSets && cvssSets[0] && cvssSets[0].BaseScore || "N/A";
        },
        sortVulnerabilities(vulns) {
            return vulns.sort((a, b) => {
                const aExploited = this.extractSeverityAndExploitation(a) === 'Exploited';
                const bExploited = this.extractSeverityAndExploitation(b) === 'Exploited';
                if (aExploited !== bExploited) {
                    return aExploited ? -1 : 1;
                }
                const aScore = this.getCVSSScore(a);
                const bScore = this.getCVSSScore(b);
                if (aScore === 'N/A' || bScore === 'N/A') {
                    return aScore === 'N/A' ? 1 : -1;
                }
                return parseFloat(bScore) - parseFloat(aScore);
            });
        },
        getCVSSScoreWithSeverity(vuln) {
            const cvss = this.getCVSSScore(vuln);
            const severity = this.determineSeverity(cvss);
            if (cvss === "N/A") {
                return {
                    score: "N/A",
                    severityClass: 'badge badge-outline text-azure',
                    severityLevel: 'N/A'
                };
            }
            return {
                score: cvss,
                severityClass: severity.class,
                severityLevel: severity.level
            };
        },
        determineSeverity(cvss) {
            const score = parseFloat(cvss);
            if (score >= 9.0 && score <= 10.0) return { class: 'badge badge-outline text-red', level: 'Critical' };
            if (score >= 7.0 && score <= 8.9) return { class: 'badge badge-outline text-orange', level: 'High' };
            if (score >= 4.0 && score <= 6.9) return { class: 'badge badge-outline text-yellow', level: 'Medium' };
            if (score >= 0.1 && score <= 3.9) return { class: 'badge badge-outline text-green', level: 'Low' };
            return { class: '', level: 'N/A' };
        },
        getCategory(vuln) {
            for (let threat of vuln.Threats || []) {
                if (threat.Type === 0) {
                    return threat.Description.Value;
                }
            }
            return "N/A";
        },
        computeStatistics() {
            this.totalVulnerabilities = this.vulnerabilities.length;
            this.exploitedCount = this.exploitedVulnerabilities.length;

            this.severityCounts = {
                'Critical': 0,
                'High': 0,
                'Medium': 0,
                'Low': 0
            };

            this.cweCounts = {};

            for (let vuln of this.vulnerabilities) {
                const severity = this.determineSeverity(this.getCVSSScore(vuln)).level;
                if (this.severityCounts[severity] !== undefined) {
                    this.severityCounts[severity]++;
                }

                // Accumulate CWE counts
                if (vuln.CWE) {
                    for (let cwe of vuln.CWE) {
                        const key = `${cwe.ID} ${cwe.Value}`;
                        if (this.cweCounts[key]) {
                            this.cweCounts[key]++;
                        } else {
                            this.cweCounts[key] = 1;
                        }
                    }
                }
            }

            this.categoryCounts = {};
            const categories = [
                "Elevation of Privilege",
                "Security Feature Bypass",
                "Remote Code Execution",
                "Information Disclosure",
                "Denial of Service",
                "Spoofing",
                "Edge - Chromium"
            ];

            for (let category of categories) {
                this.categoryCounts[category] = this.vulnerabilities.filter(vuln => {
                    for (let threat of vuln.Threats || []) {
                        if (threat.Type === 0) {
                            if (category === "Edge - Chromium" && threat.ProductID[0] === "11655") {
                                return true;
                            }
                            if (threat.Description.Value === category && threat.ProductID[0] !== "11655") {
                                return true;
                            }
                        }
                    }
                    return false;
                }).length;
            }
        },
    }
}
</script>

<style scoped>
.modern-card {
    border: none;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    flex-direction: column;
}

.form-select {
    width: auto;
    display: inline-block;
}
</style>
