import { createRouter, createWebHistory } from 'vue-router';
import PageMain from './components/PageMain.vue';
import PagePatchTuesday from './components/PagePatchTuesday.vue';
import PageStatistics from './components/PageStatistics.vue';
import PageExploits from './components/PageExploits.vue';
import PageAbout from './components/PageAbout.vue';

const routes = [
  { path: '/', component: PageMain },
  { path: '/patchtuesday', component: PagePatchTuesday, props: { isPatchTuesdayPage: true } },
  { path: '/statistics', component: PageStatistics },
  { path: '/exploits', component: PageExploits },
  { path: '/about', component: PageAbout }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
