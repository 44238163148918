<template>
    <div v-if="isLoading" class="loader-container">
        <div class="text-center">
            <div class="text-secondary mb-3">Loading New Exploits data</div>
            <div class="progress progress-sm">
                <div class="progress-bar progress-bar-indeterminate"></div>
            </div>
        </div>
    </div>

    <div class="page-wrapper">

        <div class="page-header d-print-none">
            <div class="container-xl">
                <div class="row g-2 align-items-center">
                    <div class="col">
                        <div class="page-pretitle">
                            Microsoft
                        </div>
                        <h2 class="page-title">
                            New Exploits
                        </h2>
                    </div>
                </div>
            </div>
        </div><br />

        <div class="container mt-3">
            <div class="card modern-card shadow-sm mb-4">
                <div class="card-header">
                    <h3 class="m-0">Lists of recently published exploits that pertain to Microsoft-issued CVEs.</h3>
                </div>
                <div class="card-body">
                    <DataTable :value="exploitsDetails" stripedRows columnResizeMode="fit">
                        <Column header="Exploit">
                            <template #body="slotProps">
                                <div>
                                    <p><a :href="'https://msrc.microsoft.com/update-guide/vulnerability/' + slotProps.data.cve_id"
                                            target="_blank">{{ slotProps.data.cve_id }}</a></p>
                                    <ul>
                                        <p><svg xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-brand-github" width="24" height="24"
                                                viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                                stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path
                                                    d="M9 19c-4.3 1.4 -4.3 -2.5 -6 -3m12 5v-3.5c0 -1 .1 -1.4 -.5 -2c2.8 -.3 5.5 -1.4 5.5 -6a4.6 4.6 0 0 0 -1.3 -3.2a4.2 4.2 0 0 0 -.1 -3.2s-1.1 -.3 -3.5 1.3a12.3 12.3 0 0 0 -6.2 0c-2.4 -1.6 -3.5 -1.3 -3.5 -1.3a4.2 4.2 0 0 0 -.1 3.2a4.6 4.6 0 0 0 -1.3 3.2c0 4.6 2.7 5.7 5.5 6c-.6 .6 -.6 1.2 -.5 2v3.5" />
                                            </svg>&nbsp;<a :href="slotProps.data.html_url" target="_blank">{{
                                                slotProps.data.name
                                            }}</a>
                                        </p>
                                        <p><svg xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-file-text" width="24" height="24"
                                                viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                                stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                                <path
                                                    d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                                                <path d="M9 9l1 0" />
                                                <path d="M9 13l6 0" />
                                                <path d="M9 17l6 0" />
                                            </svg>&nbsp;{{ slotProps.data.description }}</p>
                                        <p><span href="javascript:void(0)" @click="showDialog(slotProps.data)"><svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    class="icon icon-tabler icon-tabler-biohazard" width="24" height="24"
                                                    viewBox="0 0 24 24" stroke-width="2" @click="showDialog(slotProps.data)"
                                                    stroke="currentColor" fill="none" stroke-linecap="round"
                                                    stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                                                    <path
                                                        d="M11.939 14c0 .173 .048 .351 .056 .533l0 .217a4.75 4.75 0 0 1 -4.533 4.745l-.217 0m-4.75 -4.75a4.75 4.75 0 0 1 7.737 -3.693m6.513 8.443a4.75 4.75 0 0 1 -4.69 -5.503l-.06 0m1.764 -2.944a4.75 4.75 0 0 1 7.731 3.477l0 .217m-11.195 -3.813a4.75 4.75 0 0 1 -1.828 -7.624l.164 -.172m6.718 0a4.75 4.75 0 0 1 -1.665 7.798" />
                                                </svg></span>&nbsp;<span class="badge badge-outline text-red cursor-pointer"
                                                @click="showDialog(slotProps.data)">More Exploits by CVE</span></p>
                                    </ul>
                                </div>
                            </template>
                        </Column>
                        <Column field="owner" header="Author"></Column>
                        <Column field="inserted_at" header="Added">
                            <template #body="slotProps">
                                <span class="badge badge-outline text-indigo">
                                    {{ formatDate(slotProps.data.inserted_at) }}
                                </span>
                            </template>
                        </Column>
                    </DataTable>

                    <PVDialog v-model:visible="dialogVisible" :style="{ width: '75vw' }"
                        :header="selectedExploit ? selectedExploit.name : 'Details'">
                        <template #header>
                            <h3 class="dialog-header">{{ selectedExploit ? selectedExploit.name : 'More Exploits' }}</h3>
                        </template>

                        <div class="dialog-body">
                            <ul class="exploit-list">
                                <li v-for="poc in selectedExploit?.pocDetails" :key="poc.id" class="exploit-item">
                                    <div class="exploit-name">
                                        <a :href="poc.html_url" target="_blank">{{ poc.full_name }}</a>
                                    </div>
                                    <div class="exploit-stars">
                                        {{ poc.stargazers_count }}&nbsp;
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon-star" width="24" height="24"
                                            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                            stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path
                                                d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" />
                                        </svg>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </PVDialog>

                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import config from '@/config';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import PrimeVueDialog from 'primevue/dialog';

export default {
    name: 'PageExploits',
    components: {
        DataTable,
        Column,
        'PVDialog': PrimeVueDialog
    },
    data() {
        return {
            isLoading: true,
            exploitsDetails: [],
            dialogVisible: false,
            selectedExploit: null,
            BASE_URL: config.EXPLOITS_URL,
        };
    },
    created() {
        this.fetchExploitsDetails();
    },
    methods: {
        async fetchExploitsDetails() {
            this.isLoading = true;
            try {
                const response = await fetch(this.BASE_URL);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                this.exploitsDetails = data.map(item => ({
                    cve_id: item.exploit.cve_id,
                    name: item.exploit.name,
                    html_url: item.exploit.html_url,
                    description: item.exploit.description,
                    owner: item.exploit.owner,
                    inserted_at: item.exploit.inserted_at,
                    pocDetails: item.pocDetails,
                }));
                this.isLoading = false;
            } catch (error) {
                console.error('Fetch error:', error);
                this.isLoading = false;
            }
        },
        showDialog(exploit) {
            this.selectedExploit = exploit;
            this.dialogVisible = true;
        },
        formatDate(value) {
            if (value) {
                return new Date(value).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: 'long',
                    year: 'numeric'
                });
            }
            return '';
        },
    }
};
</script>

<style scoped>

.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    flex-direction: column;
}

.loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
.dialog-header {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 1rem;
}

.dialog-body {
    padding: 1rem;
}

.exploit-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.exploit-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    border-bottom: 1px solid #eee;
}

.exploit-name a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
}

.exploit-name a:hover {
    text-decoration: underline;
}

.icon-star {
    color: #F9C23C;
}

.exploit-stars {
    display: flex;
    align-items: center;
}
</style>