// src/config.js
const isProduction = process.env.NODE_ENV === 'production';

const config = {
  // Common base URL
  BASE_URL: isProduction ? '/api/' : 'http://localhost:3000/api/',

  // Specific endpoint for exploits
  EXPLOITS_URL: isProduction ? '/api/exploits' : 'http://localhost:3000/api/exploits',
};

export default config;
