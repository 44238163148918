<template>
    <header class="navbar navbar-expand-md d-print-none">
        <div class="container-xl">
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu"
                aria-controls="navbar-menu" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <h1 class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
                <a href="./"><img :src="logo" height="65" width="250" alt="Microsoft Patch-A-Palooza"></a>
            </h1>
            <div class="badge modern-badge bg-blue-lt">
                Next Patch Tuesday: {{ nextPatchTuesday.date }}
                <span v-if="nextPatchTuesday.isToday">(today 🎉)</span>
                <span v-else>(in {{ nextPatchTuesday.remainingDays }} days)</span>
            </div>
        </div>
    </header>

    <div v-if="showSpecialMessage" class="alert alert-important alert-danger" role="alert">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-alert-triangle" width="24" height="24"
            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
            stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M12 9v4" />
            <path
                d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z" />
            <path d="M12 16h.01" />
        </svg>
        Hi <strong>213.193.80.210</strong> - please get in touch with me at <strong>ah@primepage.de</strong> as I see constant refreshes from this IP. I
        would like to know the reason for it before I block this IP. Thank you!
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-alert-triangle" width="24" height="24"
            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
            stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M12 9v4" />
            <path
                d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z" />
            <path d="M12 16h.01" />
        </svg>
    </div>

    <header class="navbar-expand-md">
        <div class="collapse navbar-collapse" id="navbar-menu">
            <div class="navbar">
                <div class="container-xl">
                    <div class="row flex-fill align-items-center">
                        <div class="col">
                            <ul class="navbar-nav">
                                <li class="nav-item">
                                    <router-link to="/" class="nav-link" exact-active-class="active">
                                        <span class="nav-link-icon d-md-none d-lg-inline-block">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                                                viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                                stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M5 12l-2 0l9 -9l9 9l-2 0" />
                                                <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
                                                <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
                                            </svg>
                                        </span>
                                        <span class="nav-link-title">
                                            Patch Summary
                                        </span>
                                    </router-link>
                                </li>

                                <li class="nav-item">
                                    <router-link to="/patchtuesday" class="nav-link" exact-active-class="active">
                                        <span class="nav-link-icon d-md-none d-lg-inline-block">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-lock-check" width="24" height="24"
                                                viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                                stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path
                                                    d="M11.5 21h-4.5a2 2 0 0 1 -2 -2v-6a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v.5" />
                                                <path d="M11 16a1 1 0 1 0 2 0a1 1 0 0 0 -2 0" />
                                                <path d="M8 11v-4a4 4 0 1 1 8 0v4" />
                                                <path d="M15 19l2 2l4 -4" />
                                            </svg>
                                        </span>
                                        <span class="nav-link-title">
                                            Patch Tuesday
                                        </span>
                                    </router-link>
                                </li>

                                <li class="nav-item">
                                    <router-link to="/exploits" class="nav-link" exact-active-class="active">
                                        <span class="nav-link-icon d-md-none d-lg-inline-block">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-biohazard" width="24" height="24"
                                                viewBox="0 0 24 24" stroke-width="2" @click="showDialog(slotProps.data)"
                                                stroke="currentColor" fill="none" stroke-linecap="round"
                                                stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                                                <path
                                                    d="M11.939 14c0 .173 .048 .351 .056 .533l0 .217a4.75 4.75 0 0 1 -4.533 4.745l-.217 0m-4.75 -4.75a4.75 4.75 0 0 1 7.737 -3.693m6.513 8.443a4.75 4.75 0 0 1 -4.69 -5.503l-.06 0m1.764 -2.944a4.75 4.75 0 0 1 7.731 3.477l0 .217m-11.195 -3.813a4.75 4.75 0 0 1 -1.828 -7.624l.164 -.172m6.718 0a4.75 4.75 0 0 1 -1.665 7.798" />
                                            </svg>
                                        </span>
                                        <span class="nav-link-title">
                                            New Exploits
                                        </span>
                                    </router-link>
                                </li>

                                <li class="nav-item">
                                    <router-link to="/statistics" class="nav-link" exact-active-class="active">
                                        <span class="nav-link-icon d-md-none d-lg-inline-block">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-chart-bar" width="24" height="24"
                                                viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                                stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path
                                                    d="M3 12m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z">
                                                </path>
                                                <path
                                                    d="M9 8m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z">
                                                </path>
                                                <path
                                                    d="M15 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z">
                                                </path>
                                                <path d="M4 20l14 0"></path>
                                            </svg>
                                        </span>
                                        <span class="nav-link-title">
                                            Patch statistics
                                        </span>
                                    </router-link>
                                </li>

                                <li class="nav-item">
                                    <router-link to="/about" class="nav-link" exact-active-class="active">
                                        <span class="nav-link-icon d-md-none d-lg-inline-block">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-user-circle" width="24" height="24"
                                                viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                                stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                                                <path d="M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                                                <path d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855">
                                                </path>
                                            </svg>
                                        </span>
                                        <span class="nav-link-title">
                                            About
                                        </span>
                                    </router-link>
                                </li>

                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import axios from 'axios';
import logo from '@/assets/logo.png';

export default {
    name: 'PageHeader',
    data() {
        return {
            showSpecialMessage: false,
            targetIP: '213.193.80.210',
        };
    },
    setup() {
        return {
            logo,
        };
    },
    created() {
        this.checkIPAddress();
    },
    methods: {
        async checkIPAddress() {
            try {
                const response = await axios.get('https://api.ipify.org?format=json');
                const userIP = response.data.ip;
                this.showSpecialMessage = userIP === this.targetIP;
            } catch (error) {
                console.error('Error fetching IP address:', error);
            }
        },
    },
    computed: {
        nextPatchTuesday() {
            const now = new Date();
            now.setHours(0, 0, 0, 0);
            const currentMonth = now.getMonth();
            const currentYear = now.getFullYear();

            function getSecondTuesday(month, year) {
                const firstDayOfMonth = new Date(year, month, 1);
                const dayOfWeek = firstDayOfMonth.getDay();

                let firstTuesday;
                if (dayOfWeek === 2) {
                    firstTuesday = firstDayOfMonth.getDate();
                } else if (dayOfWeek < 2) {
                    firstTuesday = firstDayOfMonth.getDate() + (2 - dayOfWeek);
                } else {
                    firstTuesday = firstDayOfMonth.getDate() + (9 - dayOfWeek);
                }

                let secondTuesday = new Date(year, month, firstTuesday + 7);
                return secondTuesday;
            }

            let secondTuesday = getSecondTuesday(currentMonth, currentYear);

            if (now > secondTuesday) {
                const nextMonth = currentMonth === 11 ? 0 : currentMonth + 1;
                const nextYear = currentMonth === 11 ? currentYear + 1 : currentYear;
                secondTuesday = getSecondTuesday(nextMonth, nextYear);
            }

            const isToday = now.toDateString() === secondTuesday.toDateString();
            const remainingDays = isToday ? 0 : Math.ceil((secondTuesday - now) / (1000 * 60 * 60 * 24));

            return {
                date: `${secondTuesday.getFullYear()}-${(secondTuesday.getMonth() + 1).toString().padStart(2, '0')}-${secondTuesday.getDate().toString().padStart(2, '0')}`,
                remainingDays,
                isToday
            };
        },
    },
}
</script>
<style>
.modern-card {
    border: none;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.patch-title {
    font-family: 'Segoe UI', 'Noto Sans', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 24px;
    font-weight: 700;
    -webkit-background-clip: text;
    color: #0166B8;
    display: inline-block;
}

.nav-pills .nav-link {
    color: #555;
    margin-right: 0.5rem;
    transition: color 0.3s ease-in-out;
}

.nav-pills .nav-link.active,
.nav-pills .nav-link:hover {
    color: #007bff;
}

.modern-badge {
    font-size: 0.7rem;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    background-color: #e9ecef;
    color: #007bff;
}

.nav-item .active {
    font-weight: bold;
}

.nav-item .active .icon {
    stroke-width: 1.75;
}

@media (max-width: 768px) {
    .patch-title {
        font-size: 18px;
    }

    .nav-pills .nav-link {
        padding: 8px 10px;
    }

    .header .container {
        padding: 0 15px;
    }

    .header .card-body {
        flex-direction: column;
        align-items: center;
    }

    .nav {
        margin-bottom: 15px;
    }
}</style>
