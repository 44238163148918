<template>
  <!-- Footer -->
  <div class="container mt-3">
    <div class="card modern-card shadow-sm h-100 footer-card">
      <div class="card-body">
        <div class="footer-content">
          <!-- Copyright Info -->
          <div class="footer-section copyright">
            &copy; 2023 - 2024 Alexander Hagenah
          </div>
          <!-- Icons Section -->
          <div class="footer-section icons">
            <a href="https://www.linkedin.com/in/alexhagenah/" target="_blank" class="footer-icon-link" title="LinkedIn">
              <IconBrandLinkedin class="icon linkedin-color" />
            </a>
            <a href="https://api.msrc.microsoft.com/cvrf/v2.0/swagger/index" target="_blank" class="footer-icon-link"
              title="MSRC CVRF API">
              <IconApi class="icon api-color" />
            </a>
            <a href="https://github.com/xaitax/PatchaPalooza" target="_blank" class="footer-icon-link" title="GitHub CLI">
              <IconBrandGithub class="icon github-color" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { IconBrandLinkedin, IconApi, IconBrandGithub } from '@tabler/icons-vue';

export default {
  name: 'PageFooter',
  components: {
    IconBrandLinkedin,
    IconApi,
    IconBrandGithub
  }
}
</script>
  
<style scoped>
.footer-card {
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-section {
  display: flex;
  align-items: center;
}

.modern-card {
  border: none;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.copyright {
  color: #6c757d;
  font-size: 0.875rem;
}

.icons .footer-icon-link {
  margin-right: 1rem;
  text-decoration: none;
}

.icons .footer-icon-link:last-child {
  margin-right: 0;
}

.icon {
  width: 24px;
  height: 24px;
  stroke-width: 1.5;
}

.home-color {
  stroke: #000000;
}

.linkedin-color {
  stroke: #0077b5;
}

.api-color {
  stroke: #3429ff;
}

.github-color {
  stroke: #333;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    text-align: center;
  }

  .footer-section {
    margin-bottom: 0.5rem;
  }

  .footer-section:last-child {
    margin-bottom: 0;
  }
}
</style>
  