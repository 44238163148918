<template>
    <div class="col-6">
        <div class="card modern-card shadow-sm h-100">
            <div class="card-header">
                <component :is="icon" class="icon" stroke-width="2" color="currentColor" />&nbsp;
                <h3 class="card-title">{{ title }}</h3>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6" v-for="(item, index) in items" :key="index">
                        <div class="category-item">
                            <span class="category-emoji">{{ getEmoji(item.name || item.category) }}</span>
                            <div class="category-info">
                                <div class="category-title">{{ item.name || item.category }}</div>
                                <div class="category-count">{{ item.count }} vulnerabilities</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        icon: String,
        items: {
            type: Array,
            required: true
        },
        getEmoji: {
            type: Function,
            required: true
        }
    }
};
</script>

<style scoped>
.category-item {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 1rem;
    background-color: #ffffff;
}

.category-emoji {
    font-size: 2rem;
    margin-right: 1rem;
}

.category-info {
    flex-grow: 1;
}

.category-title {
    font-weight: 500;
    margin-bottom: 0.25rem;
}

.category-count {
    color: #6c757d;
}
</style>
