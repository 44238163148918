<template>
    <div v-if="isLoading" class="loader-container">
        <div class="text-center">
            <div class="text-secondary mb-3">Loading data</div>
            <div class="progress progress-sm">
                <div class="progress-bar progress-bar-indeterminate"></div>
            </div>
        </div>
    </div>

    <div class="page-wrapper">
        <div class="page-header d-print-none">
            <div class="container-xl">
                <div class="row g-2 align-items-center">
                    <div class="col">
                        <div class="page-pretitle">Microsoft Security Update</div>
                        <h2 class="page-title">{{ documentTitle }}</h2>
                    </div>
                    <div class="col-auto ms-auto d-print-none">
                        <div class="input-group">
                            <span class="input-group-text bg-white">
                                <IconCalendar class="icon" stroke-width="2" color="currentColor" />
                            </span>
                            <select class="form-select" v-if="filteredSummaries.length > 0" v-model="selectedMonth"
                                @change="retrieveDataForSelectedMonth">
                                <option v-for="summary in filteredSummaries" :key="summary.ID" :value="summary.ID">
                                    {{ summary.ID }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div><br />

        <!-- Vulnerabilities -->
        <div class="container mt-3">
            <div class="col-12">
                <div class="card modern-card shadow-sm h-100">
                    <div class="card-header">
                        <IconChartArrowsVertical class="icon" stroke-width="2" color="currentColor" />&nbsp;
                        <h3 class="card-title">
                            Vulnerabilities ({{ totalVulnerabilitiesCount }})
                        </h3>
                    </div>
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"
                                v-for="(stat, index) in overallStats" :key="index">
                                <div class="card card-sm no-border">
                                    <div class="card-body">
                                        <div class="row align-items-center">
                                            <div class="col-auto">
                                                <span :class="['avatar', getColor(stat.title)]">
                                                    <span class="text-white">{{ stat.value }}</span>
                                                </span>
                                            </div>
                                            <div class="col">
                                                <div><strong>{{ stat.title }}</strong></div>
                                                <div class="text-secondary">vulnerabilities</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container mt-3">
            <div class="row">
                <ThreatCard title="Threat Categories" icon="IconVirusSearch" :items="sortedCategoryCounts"
                    :get-emoji="getEmoji" />
                <ThreatCard title="Affected Products" icon="IconBrandWindows" :items="sortedVulnerabilityCountsByName"
                    :get-emoji="getEmoji" />
            </div>
        </div>

        <div class="container mt-3" v-for="(tableData, index) in nonEmptyTablesData" :key="index">
            <div class="card modern-card shadow-sm h-100">
                <div class="card-header">
                    <component :is="tableData.icon" class="icon" stroke-width="2" color="currentColor" />&nbsp;
                    <h5 class="card-title">{{ tableData.title }} ({{ tableData.filteredVulnerabilities.length }})</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between mb-2 align-items-center">
                        <div class="input-group search-input-group">
                            <span class="input-group-text">
                                <i class="pi pi-search"></i>
                            </span>
                            <InputText type="text" v-model="state.filters[index === 0 ? 'exploited' : 'notExploited']"
                                @input="state.searchPerformed[index === 0 ? 'exploited' : 'notExploited'] = true"
                                placeholder="Keyword Search" class="p-inputtext-sm form-control" />
                        </div>
                        <PVButton label="Export CSV" icon="pi pi-download" class="export-button"
                            @click="exportCSV(index)" :disabled="tableData.filteredVulnerabilities.length === 0">
                        </PVButton>
                    </div>

                    <DataTable v-if="tableData.filteredVulnerabilities.length > 0"
                        :ref="el => { if (el) dataTablesRefs[index] = el; }" :removableSort="true"
                        :value="tableData.filteredVulnerabilities" :paginator="true" :rows="10"
                        :rowsPerPageOptions="[10, 20, 50]" responsiveLayout="flip" exportFilename="patchpalooza">
                        <Column field="CVE" header="CVE" :sortable="true"
                            :style="{ minWidth: '150px', 'white-space': 'nowrap' }">
                            <template #body="slotProps">
                                <a :href="'https://msrc.microsoft.com/update-guide/vulnerability/' + slotProps.data.CVE"
                                    target="_blank" rel="noopener noreferrer">
                                    {{ slotProps.data.CVE }}
                                </a>
                            </template>
                        </Column>
                        <Column field="CVSS" header="CVSS" :sortable="true">
                            <template #body="slotProps">
                                <span :class="[getCVSSScoreWithSeverity(slotProps.data).severityClass, 'hover-pointer']"
                                    :title="getCVSSScoreWithSeverity(slotProps.data).severityLevel">
                                    <template v-if="getCVSSScoreWithSeverity(slotProps.data).score !== 'N/A'">
                                        <span href="javascript:void(0)"
                                            @click="openCvssDialog(slotProps.data.CVSSScoreSets[0])">
                                            {{ getCVSSScoreWithSeverity(slotProps.data).score }}
                                            &nbsp;
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-list-details" width="24" height="24"
                                                viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                                stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M13 5h8" />
                                                <path d="M13 9h5" />
                                                <path d="M13 15h8" />
                                                <path d="M13 19h5" />
                                                <path
                                                    d="M3 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
                                                <path
                                                    d="M3 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
                                            </svg>
                                        </span>
                                    </template>
                                    <template v-else>
                                        {{ getCVSSScoreWithSeverity(slotProps.data).score }}
                                        &nbsp;
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            class="icon icon-tabler icon-tabler-list-details" width="24" height="24"
                                            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                            stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M13 5h8" />
                                            <path d="M13 9h5" />
                                            <path d="M13 15h8" />
                                            <path d="M13 19h5" />
                                            <path
                                                d="M3 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
                                            <path
                                                d="M3 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
                                        </svg>
                                    </template>
                                </span>
                            </template>
                        </Column>
                        <Column field="Title.Value" header="Title" :sortable="true" />
                        <Column field="FAQ" header="Info">
                            <template #body="slotProps">
                                <a href="javascript:void(0)"
                                    @click="openFaqDialog(getFAQ(slotProps.data), slotProps.data.CVE, slotProps.data.Title.Value)">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        class="icon icon-tabler icon-tabler-info-hexagon" width="24" height="24"
                                        viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                        stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path
                                            d="M19.875 6.27c.7 .398 1.13 1.143 1.125 1.948v7.284c0 .809 -.443 1.555 -1.158 1.948l-6.75 4.27a2.269 2.269 0 0 1 -2.184 0l-6.75 -4.27a2.225 2.225 0 0 1 -1.158 -1.948v-7.285c0 -.809 .443 -1.554 1.158 -1.947l6.75 -3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033z" />
                                        <path d="M12 9h.01" />
                                        <path d="M11 12h1v4h1" />
                                    </svg>
                                </a>
                            </template>
                        </Column>
                        <Column field="Category" header="Threat Category" :sortable="true">
                            <template #body="slotProps">
                                <span v-if="getCategory(slotProps.data)">
                                    {{ getCategory(slotProps.data) || 'Unknown Category' }}
                                </span>
                            </template>
                        </Column>
                        <Column field="Product" header="Product" :sortable="true">
                            <template #body="slotProps">
                                <span
                                    v-if="slotProps.data.ProductStatuses && slotProps.data.ProductStatuses.length > 0 && getCategoryNameByProductId(slotProps.data.ProductStatuses[0].ProductID[0])">
                                    {{ getCategoryNameByProductId(slotProps.data.ProductStatuses[0].ProductID[0]) || ''
                                    }}
                                </span>
                            </template>
                        </Column>
                    </DataTable>

                    <div v-if="tableData.filteredVulnerabilities.length === 0 && state.searchPerformed[index === 0 ? 'exploited' : 'notExploited']"
                        class="alert alert-warning">
                        No vulnerabilities found for the search criteria.
                    </div>

                    <PVDialog v-model:visible="showFaqDialog" :header="dialogHeader" :style="{ width: '85vw' }"
                        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                        <div v-html="selectedFaqContent"></div>
                        <template #footer>
                            <button class="btn btn-primary" @click="showFaqDialog = false">Close</button>
                        </template>
                    </PVDialog>

                    <PVDialog v-model:visible="showCvssDialog" header="CVSS Details" :style="{ width: '50vw' }">
                        <div class="cvss-details">
                            <div class="cvss-vector-link">
                                <p><strong>Vector: </strong> <a
                                        :href="'https://www.first.org/cvss/calculator/3.1#' + selectedCvssScoreSet.Vector"
                                        target="_blank">
                                        {{ selectedCvssScoreSet.Vector }}
                                    </a></p>
                            </div>
                            <br />
                            <div class="cvss-scores-table">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Base Score:</td>
                                            <td>{{ selectedCvssScoreSet.BaseScore }}</td>
                                        </tr>
                                        <tr>
                                            <td>Temporal Score:</td>
                                            <td>{{ selectedCvssScoreSet.TemporalScore }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <br />
                            <div class="cvss-vector-table">
                                <table>
                                    <tbody>
                                        <tr v-for="(value, key) in parseCvssVector(selectedCvssScoreSet.Vector)"
                                            :key="key">
                                            <td>{{ key }}</td>
                                            <td :class="getCvssColumnClass(key, value)">{{ value }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <template #footer>
                            <button class="btn btn-primary" @click="showCvssDialog = false">Close</button>
                        </template>
                    </PVDialog>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { IconBrandWindows, IconCalendar, IconChartArrowsVertical, IconVirusSearch, IconBiohazard, IconBiohazardOff } from '@tabler/icons-vue';
import { reactive } from 'vue';
import InputText from 'primevue/inputtext';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import PrimeVueButton from 'primevue/button';
import PrimeVueDialog from 'primevue/dialog';
import ThreatCard from '@/components/ThreatCard.vue';
import config from '@/config';

export default {
    name: 'App',
    components: {
        InputText,
        Column,
        DataTable,
        'PVButton': PrimeVueButton,
        'PVDialog': PrimeVueDialog,
        IconBrandWindows,
        IconCalendar,
        IconChartArrowsVertical,
        IconVirusSearch,
        IconBiohazard,
        IconBiohazardOff,
        ThreatCard
    },
    props: {
        isPatchTuesdayPage: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        const state = reactive({
            filters: {
                exploited: '',
                notExploited: ''
            },
            searchPerformed: {
                exploited: false,
                notExploited: false
            }
        });
        return { state };
    },
    data() {
        return {
            isLoading: true,
            documentTitle: 'Loading...',
            vulnerabilities: [],
            patchTuesdayDate: '',
            totalVulnerabilities: 0,
            productCategoryMap: {},
            exploitedCount: 0,
            categoryCounts: {},
            apiData: {},
            vulnerabilityCountsByName: {},
            summaries: [],
            BASE_URL: config.BASE_URL,
            selectedMonth: '',
            severityCounts: { 'Critical': 0, 'High': 0, 'Medium': 0, 'Low': 0 },
            dataTablesRefs: {},
            showFaqDialog: false,
            selectedFaqContent: '',
            dialogHeader: '',
            showCvssDialog: false,
            selectedCvssScoreSet: null,
            isValidPatchTuesdayDate: true
        };
    },
    computed: {
        tablesData() {
            return [
                { title: 'Exploited', icon: IconBiohazard, vulnerabilities: this.exploitedVulnerabilities },
                { title: 'Not Exploited', icon: IconBiohazardOff, vulnerabilities: this.notExploitedVulnerabilities }
            ];
        },
        filteredTablesData() {
            return this.tablesData.map((table, index) => {
                const filterText = this.state.filters[index === 0 ? 'exploited' : 'notExploited'].toLowerCase();
                return {
                    ...table,
                    filteredVulnerabilities: this.filterVulnerabilities(table.vulnerabilities, filterText),
                    hasResults: this.hasResults(table.vulnerabilities, filterText)
                };
            });
        },
        nonEmptyTablesData() {
            return this.filteredTablesData.filter(tableData => tableData.hasResults || this.state.filters[tableData.title === 'Exploited' ? 'exploited' : 'notExploited']);
        },
        exploitedVulnerabilities() {
            return this.filterAndSortVulnerabilities('Exploited');
        },
        notExploitedVulnerabilities() {
            return this.filterAndSortVulnerabilities('Not Exploited');
        },
        filteredSummaries() {
            return Array.isArray(this.summaries) ? this.summaries.filter(summary => summary.ID !== "2017-May-B") : [];
        },
        sortedVulnerabilityCountsByName() {
            return Object.entries(this.vulnerabilityCountsByName || {}).sort((a, b) => b[1] - a[1]).map(([name, count]) => ({ name, count }));
        },
        productCategories() {
            const map = {};
            this.apiData.ProductTree?.Branch?.forEach(branch => {
                branch.Items?.forEach(item => {
                    item.Items?.forEach(product => {
                        if (product.ProductID && product.Value) map[product.ProductID] = item.Name;
                    });
                });
            });
            return map;
        },
        overallStats() {
            return Object.keys(this.severityCounts).map(title => ({ title, value: this.severityCounts[title] }));
        },
        totalVulnerabilitiesCount() {
            return this.totalVulnerabilities;
        },
        sortedCategoryCounts() {
            return Object.entries(this.categoryCounts || {}).filter(([, count]) => count > 0).sort((a, b) => b[1] - a[1]).map(([category, count]) => ({ category, count }));
        },
        formattedSelectedMonth() {
            if (!this.selectedMonth) return '';
            const months = { "Jan": "January", "Feb": "February", "Mar": "March", "Apr": "April", "May": "May", "Jun": "June", "Jul": "July", "Aug": "August", "Sep": "September", "Oct": "October", "Nov": "November", "Dec": "December" };
            const [year, month] = this.selectedMonth.split('-');
            return `${months[month]} ${year}`;
        }
    },
    async mounted() {
        await this.retrieveAllSummaries();
        if (this.summaries.length > 0) {
            this.selectedMonth = this.summaries[0].ID;
            await this.retrieveDataForMonth(this.selectedMonth);
        }
        this.isLoading = false;
        this.computeVulnerabilityCountsByName();
        this.productCategoryMap = this.productCategories;
    },
    methods: {
        async retrieveAllSummaries() {
            try {
                const response = await fetch(`${this.BASE_URL}updates`, { headers: { Accept: "application/json" } });
                if (response.ok) {
                    const responseData = await response.json();
                    this.summaries = this.sortSummaries(responseData.value);
                } else {
                    throw new Error("Failed to fetch summaries");
                }
            } catch (error) {
                console.error('Error fetching the summaries:', error);
            }
        },
        async retrieveDataForMonth(monthId) {
            this.isLoading = true;
            this.vulnerabilities = [];
            this.vulnerabilityCountsByName = {};
            this.selectedMonth = monthId;
            try {
                const response = await fetch(`${this.BASE_URL}cvrf/${monthId}`, { headers: { Accept: "application/json" } });
                if (response.ok) {
                    this.apiData = await response.json();
                    this.documentTitle = this.apiData.DocumentTitle.Value;
                    if (this.isPatchTuesdayPage) {
                        this.patchTuesdayDate = this.apiData.DocumentTracking.InitialReleaseDate;
                        if (this.isValidPatchTuesday(this.patchTuesdayDate)) {
                            this.isValidPatchTuesdayDate = true;
                            this.vulnerabilities = this.apiData.Vulnerability.filter(vuln =>
                                vuln.RevisionHistory.some(revision => revision.Date === this.patchTuesdayDate)
                            );
                        } else {
                            console.error("Invalid Patch Tuesday date:", this.patchTuesdayDate);
                            this.isValidPatchTuesdayDate = false;
                        }
                    } else {
                        this.vulnerabilities = this.apiData.Vulnerability;
                    }
                    this.processVulnerabilitiesData();
                    this.computeStatistics();
                    this.computeVulnerabilityCountsByName();
                } else {
                    throw new Error("Failed to fetch data for the month");
                }
            } catch (error) {
                console.error(`Error fetching the data for month ${monthId}:`, error);
            } finally {
                this.isLoading = false;
            }
        },
        isValidPatchTuesday(dateString) {
            const date = new Date(dateString);
            const year = date.getFullYear();
            const month = date.getMonth();
            const day = date.getDate();

            const secondTuesday = this.getSecondTuesday(month, year);

            return year === secondTuesday.getFullYear() &&
                month === secondTuesday.getMonth() &&
                day === secondTuesday.getDate();
        },
        getSecondTuesday(month, year) {
            const firstDayOfMonth = new Date(year, month, 1).getDay();
            const daysUntilTuesday = (2 - firstDayOfMonth + 7) % 7;
            return new Date(year, month, daysUntilTuesday + 8); // 8 represents the second Tuesday
        },
        retrieveDataForSelectedMonth() {
            this.retrieveDataForMonth(this.selectedMonth);
        },
        processVulnerabilitiesData() {
            this.vulnerabilities = this.vulnerabilities.map(vuln => ({
                ...vuln,
                CVSS: this.getCVSSScoreWithSeverity(vuln).score,
                Category: this.getCategory(vuln) || 'Unknown Category',
                Product: this.getProduct(vuln)
            }));
        },
        filterAndSortVulnerabilities(status) {
            const vulns = this.vulnerabilities.filter(vuln => this.extractSeverityAndExploitation(vuln) === status);
            return this.sortVulnerabilities(vulns);
        },
        filterVulnerabilities(vulnerabilities, filterText) {
            return vulnerabilities.filter(vuln => {
                const matchText = text => typeof text === 'string' && text.toLowerCase().includes(filterText);
                return matchText(vuln.CVE) ||
                    matchText(vuln.Title?.Value) ||
                    matchText(this.getCategory(vuln)) ||
                    matchText(this.getProduct(vuln)) ||
                    matchText(vuln.CVSS);
            });
        },
        hasResults(vulnerabilities, filterText) {
            return vulnerabilities.some(vuln => {
                const matchText = text => typeof text === 'string' && text.toLowerCase().includes(filterText);
                return matchText(vuln.CVE) ||
                    matchText(vuln.Title?.Value) ||
                    matchText(this.getCategory(vuln)) ||
                    matchText(this.getProduct(vuln)) ||
                    matchText(vuln.CVSS);
            });
        },
        getProduct(vuln) {
            if (vuln.ProductStatuses?.length > 0) {
                const productId = vuln.ProductStatuses[0].ProductID[0];
                return this.getCategoryNameByProductId(productId) || '';
            }
            return '';
        },
        getFAQ(vuln) {
            const faqEntries = vuln.Notes.filter(note => note.Title === "FAQ");
            return faqEntries.length === 0 ? 'No FAQ available' : faqEntries.map(faq => faq.Value).join('<hr>');
        },
        openCvssDialog(cvssScoreSet) {
            this.selectedCvssScoreSet = cvssScoreSet;
            this.showCvssDialog = true;
        },
        parseCvssVector(vector) {
            const metricMap = {
                'AV:N': 'Attack Vector: Network', 'AV:A': 'Attack Vector: Adjacent', 'AV:L': 'Attack Vector: Local', 'AV:P': 'Attack Vector: Physical',
                'AC:L': 'Attack Complexity: Low', 'AC:H': 'Attack Complexity: High', 'PR:N': 'Privileges Required: None', 'PR:L': 'Privileges Required: Low',
                'PR:H': 'Privileges Required: High', 'UI:N': 'User Interaction: None', 'UI:R': 'User Interaction: Required', 'S:U': 'Scope: Unchanged',
                'S:C': 'Scope: Changed', 'C:N': 'Confidentiality: None', 'C:L': 'Confidentiality: Low', 'C:H': 'Confidentiality: High', 'I:N': 'Integrity: None',
                'I:L': 'Integrity: Low', 'I:H': 'Integrity: High', 'A:N': 'Availability: None', 'A:L': 'Availability: Low', 'A:H': 'Availability: High',
                'E:U': 'Exploit Code Maturity: Unproven', 'E:P': 'Exploit Code Maturity: Proof-of-Concept', 'E:F': 'Exploit Code Maturity: Functional',
                'E:H': 'Exploit Code Maturity: High', 'RL:O': 'Remediation Level: Official Fix', 'RL:T': 'Remediation Level: Temporary Fix', 'RL:W': 'Remediation Level: Workaround',
                'RL:U': 'Remediation Level: Unavailable', 'RC:U': 'Report Confidence: Unknown', 'RC:R': 'Report Confidence: Reasonable', 'RC:C': 'Report Confidence: Confirmed'
            };
            return vector.split('/').slice(1).reduce((parsedVector, metric) => {
                const [metricAbbreviation, value] = metric.split(':');
                const fullDescription = metricMap[`${metricAbbreviation}:${value}`] || metric;
                const splitIndex = fullDescription.lastIndexOf(':');
                parsedVector[fullDescription.substring(0, splitIndex)] = fullDescription.substring(splitIndex + 1).trim();
                return parsedVector;
            }, {});
        },
        getCvssColumnClass(key, value) {
            return value.trim() === 'Network' || value.trim() === 'Low' || value.trim() === 'None' ? 'cvss-critical' : '';
        },
        exportCSV(index) {
            if (this.dataTablesRefs[index]) {
                this.dataTablesRefs[index].exportCSV();
            } else {
                console.error('DataTable ref not found for index', index);
            }
        },
        getCategoryNameByProductId(productId) {
            return this.productCategories[productId] || '';
        },
        computeVulnerabilityCountsByName() {
            this.vulnerabilityCountsByName = {};
            const product_id_to_name = {};
            this.apiData.ProductTree?.Branch?.forEach(branch => {
                branch.Items?.forEach(item => {
                    item.Items?.forEach(sub_item => {
                        product_id_to_name[sub_item.ProductID] = item.Name;
                    });
                });
            });

            this.vulnerabilities.forEach(vulnerability => {
                const unique_names = new Set();
                vulnerability.ProductStatuses?.forEach(product_status => {
                    product_status.ProductID.forEach(pid => {
                        if (pid in product_id_to_name) unique_names.add(product_id_to_name[pid]);
                    });
                });

                unique_names.forEach(name => {
                    this.vulnerabilityCountsByName[name] = (this.vulnerabilityCountsByName[name] || 0) + 1;
                });
            });
        },
        extractSeverityAndExploitation(vuln) {
            if (Array.isArray(vuln.Threats)) {
                return vuln.Threats.some(threat => {
                    if (threat && threat.Description && typeof threat.Description.Value === 'string') {
                        return threat.Description.Value.includes("Exploited:Yes");
                    }
                    return false;
                }) ? "Exploited" : "Not Exploited";
            }
            return "Not Exploited";
        },
        getCVSSScore(vuln) {
            return vuln.CVSSScoreSets?.[0]?.BaseScore || "N/A";
        },
        sortVulnerabilities(vulns) {
            return vulns.sort((a, b) => {
                const aExploited = this.extractSeverityAndExploitation(a) === 'Exploited';
                const bExploited = this.extractSeverityAndExploitation(b) === 'Exploited';
                if (aExploited !== bExploited) return aExploited ? -1 : 1;
                const aScore = this.getCVSSScore(a);
                const bScore = this.getCVSSScore(b);
                if (aScore === 'N/A' || bScore === 'N/A') return aScore === 'N/A' ? 1 : -1;
                return parseFloat(bScore) - parseFloat(aScore);
            });
        },
        getCVSSScoreWithSeverity(vuln) {
            const cvss = this.getCVSSScore(vuln);
            const severity = this.determineSeverity(cvss);
            return cvss === "N/A" ? { score: "N/A", severityClass: 'badge badge-outline text-azure', severityLevel: 'N/A' } : { score: cvss, severityClass: severity.class, severityLevel: severity.level };
        },
        determineSeverity(cvss) {
            const score = parseFloat(cvss);
            if (score >= 9.0 && score <= 10.0) return { class: 'badge badge-outline text-red', level: 'Critical' };
            if (score >= 7.0 && score <= 8.9) return { class: 'badge badge-outline text-orange', level: 'High' };
            if (score >= 4.0 && score <= 6.9) return { class: 'badge badge-outline text-yellow', level: 'Medium' };
            if (score >= 0.1 && score <= 3.9) return { class: 'badge badge-outline text-green', level: 'Low' };
            return { class: '', level: 'N/A' };
        },
        getColor(title) {
            const colorMapping = { 'Critical': 'bg-red', 'High': 'bg-orange', 'Medium': 'bg-yellow', 'Low': 'bg-green', 'N/A': 'bg-azure' };
            return colorMapping[title] || 'bg-secondary';
        },
        getCategory(vuln) {
            return vuln.Threats?.find(threat => threat.Type === 0)?.Description.Value || "N/A";
        },
        computeStatistics() {
            this.totalVulnerabilities = this.vulnerabilities.length;
            this.severityCounts = { 'Critical': 0, 'High': 0, 'Medium': 0, 'Low': 0, 'N/A': 0 };

            this.vulnerabilities.forEach(vuln => {
                const severity = this.determineSeverity(this.getCVSSScore(vuln)).level;
                this.severityCounts[severity] = (this.severityCounts[severity] || 0) + 1;
            });

            const categories = ["Elevation of Privilege", "Security Feature Bypass", "Remote Code Execution", "Information Disclosure", "Denial of Service", "Spoofing", "Edge - Chromium"];
            this.categoryCounts = categories.reduce((acc, category) => {
                acc[category] = this.vulnerabilities.filter(vuln => vuln.Threats?.some(threat => threat.Type === 0 && (threat.Description.Value === category || (category === "Edge - Chromium" && threat.ProductID[0] === "11655")))).length;
                return acc;
            }, {});
        },
        openFaqDialog(faqContent, cve, title) {
            this.selectedFaqContent = faqContent;
            this.dialogHeader = `${cve} - ${title}`;
            this.showFaqDialog = true;
        },
        getEmoji(name) {
            const emojiMap = {
                'Edge - Chromium': '🌐', 'Remote Code Execution': '🎯', 'Elevation of Privilege': '🔝', 'Information Disclosure': '🔍',
                'Security Feature Bypass': '🛡️', 'Denial of Service': '⛔', 'Spoofing': '🎭', 'Windows': '🪟', 'Browser': '🌐', 'ESU': '⏳',
                'Azure': '☁️', 'Apps': '🛍️', 'Developer Tools': '🛠️', 'Microsoft Office': '📊', 'Exchange Server': '📧', 'SQL Server': '🗄️',
                'System Center': '🧰', 'Mariner': '🚢', 'Microsoft Dynamics': '♾️', 'Other': '⚙️'
            };
            return emojiMap[name] || '';
        },
        sortSummaries(summaries) {
            return summaries.sort((a, b) => {
                const [yearA, monthA] = a.ID.split('-');
                const [yearB, monthB] = b.ID.split('-');
                const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                return yearA !== yearB ? parseInt(yearB) - parseInt(yearA) : months.indexOf(monthB) - months.indexOf(monthA);
            });
        }
    }
}
</script>


<style scoped>
.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    flex-direction: column;
}

.modern-card {
    border: none;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-select {
    width: auto;
    display: inline-block;
}

.card:not(:first-child) {
    margin-top: 20px;
}

.cvss-details table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1em;
    table-layout: fixed;
}

.cvss-details th,
.cvss-details td {
    padding: 0.5em;
    border: 1px solid #ddd;
    text-align: left;
}

.cvss-details th:first-child,
.cvss-details td:first-child {
    font-weight: bold;
    background-color: #f9f9f9;
    width: 30%;
}

.cvss-vector-link p {
    margin: 0.5em 0;
}

.cvss-vector-link a {
    color: #007bff;
    text-decoration: none;
}

.cvss-vector-link a:hover {
    text-decoration: underline;
}

.hover-pointer:hover {
    cursor: pointer;
}

.cvss-critical {
    background-color: #ffcccc;
    color: #990000;
}

.category-item {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 1rem;
    background-color: #ffffff;
}

.category-emoji {
    font-size: 2rem;
    margin-right: 1rem;
}

.category-info {
    flex-grow: 1;
}

.category-title {
    font-weight: 500;
    margin-bottom: 0.25rem;
}

.category-count {
    color: #6c757d;
}

.no-border {
    border: none !important;
    box-shadow: none !important;
}

.d-flex.align-items-center {
    align-items: center;
}

.search-input-group {
    width: 300px;
}

.input-group-text {
    background-color: #fff;
    border-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-control {
    border-left: 0;
    box-shadow: none;
}

.input-group .form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-color: #DADFE5;
}

.input-group .form-control:focus {
    border-color: #DADFE5;
    box-shadow: none;
    outline: none;
}

@media (max-width: 767px) {
    .card-header {
        flex-direction: column;
    }

    .nav-item {
        width: 100%;
        justify-content: center;
    }

    .category-item {
        margin-bottom: 0.5rem;
    }
}
</style>
